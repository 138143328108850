
<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <projects-list head_title=""></projects-list>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsList from "@/components/ProjectsList";
export default {
  name: "ProjectsPage",
  components: {
    ProjectsList,
  },
  metaInfo() {
    return {
      title: this.$t("app.name"),
      titleTemplate: this.$t("title.our_project") + " | %s",
      meta: [
        { property: "og:title", content: this.$t("title.our_project") },
        { property: "og:type", content: "website" },
        { property: "og:url", content: window.location.href },
      ],
    };
  },
};
</script>